"use client";
import { FormEvent } from 'react'
import FAIcon from "@/base-components/icons";
import React, { useMemo } from 'react';
import { notification } from 'antd';
import type { NotificationArgsProps } from 'antd';
import { shop, employment_question, employment_job } from "@/types/graphql";
import Checkbox from "@/ui-components/checkbox";
import RadioButton from "@/ui-components/radio-button";

function question_type(type: number, idx: number, options?: string | null, required?: boolean | null | undefined)
{
	switch (type)
	{

		// Text line
		case 1: {
			return <input name="questions" aria-labelledby="firstname" required={required ?? false} className="w-full rounded border border-neutral-300 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-200 px-3 py-3 outline-none ring-primary-300 transition duration-100 focus:ring" />
		}

		// Text area
		case 2: {
			return <textarea name="questions" aria-labelledby="firstname" required={required ?? false} className="w-full h-32 rounded border border-neutral-300 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-200 px-3 py-2 outline-none ring-primary-300 transition duration-100 focus:ring" />
		}

		// Yes/No
		case 3: {
			return <div className="flex items-start flex-wrap w-full">
				<div className="w-1/2 flex items-center">
					<RadioButton
						required={required ?? false}
						id={'yes-' + idx}
						value="Yes"
						name='questions'
					/>
					<label
						htmlFor={'yes-' + idx}
						className="ms-2">
						Yes
					</label>
				</div>
				<div className="w-1/2 flex items-center">
					<RadioButton
						required={required ?? false}
						id={'no-' + idx}
						value="No"
						name='questions'
					/>
					<label
						htmlFor={'no-' + idx}
						className="ms-2">
						No
					</label>
				</div>
			</div>
		}

		// Radio buttons
		case 4: {
			if (options !== undefined && options != null && options.length > 0)
			{
				return <div className="flex items-start flex-wrap w-full">{
					options.split(",").map((option: string, optionIdx: number) => (

						<div
							key={optionIdx}
							className="w-full lg:w-1/2 flex items-center mb-2">
							<RadioButton
								required={required ?? false}
								id={'radio-' + option + '-' + optionIdx}
								value={option}
								name='questions'
							/>
							<label
								htmlFor={'radio-' + option + '-' + optionIdx}
								className="ms-2">
								{option}
							</label>
						</div>

					))
				}
				</div>
			}
		}

		// Checkboxes
		case 5: {
			if (options !== undefined && options != null && options.length > 0)
			{

				return <div className="flex items-start flex-wrap w-full">{
					options.split(",").map((option: string, optionIdx: number) => (

						<div
							key={optionIdx}
							className="w-full lg:w-1/2 flex items-center mb-2">
							<Checkbox
								required={required ?? false}
								id={'checkbox-' + option + '-' + optionIdx}
								value={option}
								name='questions'
							/>
							<label
								htmlFor={'checkbox-' + option + '-' + optionIdx}
								className="ms-2">
								{option}
							</label>
						</div>

					))
				}
				</div>
			}
		}
	}
}

export default function FormEmployment({ title_styles = '', data, columns = 'two' }: { title_styles: string, data: shop, columns?: string })
{

	type NotificationType = 'success' | 'error';

	const [api, contextHolder] = notification.useNotification();
	type NotificationPlacement = NotificationArgsProps['placement'];
	const Context = React.createContext({ name: 'Default' });

	const openNotification = (placement: NotificationPlacement, message: string, type: NotificationType) =>
	{
		const capitalized = type.charAt(0).toUpperCase() + type.slice(1);
		api[type]({
			message: capitalized,
			description: <Context.Consumer>{() => message}</Context.Consumer>,
			placement,
		});
	};

	function getBase64(file: any): string
	{
		var reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = function ()
		{
			if (reader && reader.result)
			{
				const fileStr = reader.result.toString();
				if (fileStr.substring(0, 5) == "data:")
				{
					let fdp = fileStr.indexOf(";");
					return fileStr.substring(fdp + 8);
				} else
				{
					return fileStr;
				}
			}
		};
		reader.onerror = function (error)
		{
			openNotification('bottomRight', "Cannot read the attached file.", "error");
		};
		return '';
	}

	const contextValue = useMemo(() => ({ name: 'Contact Form' }), []);

	async function onSubmit(event: FormEvent<HTMLFormElement>)
	{
		event.preventDefault()

		const formData = new FormData(event.currentTarget);


		const files = (document.querySelector("#resume") as HTMLInputElement)?.files;
		let fty = "";

		if (files && files.length > 0)
		{
			const file = files[0];
			if (file.type == "text/plain")
			{
				fty = "txt";
			} else if (file.type == "application/msword")
			{
				fty = "doc";
			} else if (
				file.type ==
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document"
			)
			{
				fty = "docx";
			} else if (file.type == "application/pdf")
			{
				fty = "pdf";
			} else
			{
				openNotification('bottomRight', "Only TXT, DOC, DOCX, PDF files allowed", "error");
				return;
			}
			formData.append("contentType", fty);
			formData.append("fileName", file.name);
			formData.append('fileData', getBase64(file));
		}

		const response = await fetch('https://services-dev.autovitals.com/contact.asmx/SubmitEmploymentRequest?' + (new URLSearchParams(formData as any).toString()), {
			method: 'GET'
		})

		let txxResponse = await response.text();
		if (txxResponse.startsWith('harry({'))
		{
			txxResponse = txxResponse.replace('harry({', '{')
		}
		if (txxResponse.endsWith('});'))
		{
			txxResponse = txxResponse.replace('});', '}')
		}

		const json = JSON.parse(txxResponse);
		if (json && json.status && json.message)
		{
			openNotification('bottomRight', json.message, json.status);
		}
	}

	const columns_per_row: any = (columns === 'one' || columns === 'two') &&
	{
		container: {
			one: 'grid-cols-1 max-w-screen-md mx-auto',
			two: 'w-full',
		},
		questions: {
			one: 'grid-cols-1',
			two: 'md:grid-cols-2',
		}
	}

	return (
		<form
			onSubmit={onSubmit}
			className={`${columns_per_row['container'][columns]}
			flex flex-wrap`}>

			{/* Title */}
			<label
				htmlFor="jobTitle"
				className="inline-block mb-8">
				<h3 className={`${title_styles}
				dark:text-white`}>

					Position Applied For

				</h3>
			</label>

			{/* Desired position */}
			<div className={`w-full mb-8`}>

				<select
					id="jobTitle"
					name="jobTitle"
					className="w-full rounded border border-neutral-300 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-200 px-3 py-4 outline-none ring-primary-300 transition duration-100 focus:ring">
					<option value="default">
						Choose a desired position
					</option>
					{
						data?.employment_jobs?.map((position: employment_job, idx: number) => (
							position.title &&
							<option
								key={idx}
								value={position.title}>
								{position.title}
							</option>
						))
					}
				</select>

			</div>

			{/* Personal data */}
			<div className={`${columns_per_row['questions'][columns]}
			w-full grid gap-8 mb-12`}>
				<div>
					<label htmlFor="firstName" className="mb-1 inline-block text-p5">First name*</label>
					<input id="firstName" required={data?.info?.employment_first_name_required ?? false} name="firstName" autoComplete="given-name" className="w-full rounded border border-neutral-300 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-200 px-3 py-3 outline-none ring-primary-300 transition duration-100 focus:ring" />
				</div>
				<div>
					<label htmlFor="lastName" className="mb-1 inline-block text-p5">Last name*</label>
					<input id="lastName" required={data?.info?.employment_last_name_required ?? false} name="lastName" autoComplete="family-name" className="w-full rounded border border-neutral-300 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-200 px-3 py-3 outline-none ring-primary-300 transition duration-100 focus:ring" />
				</div>
				<div className="">
					<label htmlFor="email" className="mb-1 inline-block text-p5">Email</label>
					<input id="email" required={data?.info?.employment_email_required ?? false} name="email" autoComplete="email" className="w-full rounded border border-neutral-300 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-200 px-3 py-3 outline-none ring-primary-300 transition duration-100 focus:ring" />
				</div>
				<div className="">
					<label htmlFor="phone" className="mb-1 inline-block text-p5">Phone*</label>
					<input id="phone" required={data?.info?.employment_phone_required ?? false} name="phone" autoComplete="tel" className="w-full rounded border border-neutral-300 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-200 px-3 py-3 outline-none ring-primary-300 transition duration-100 focus:ring" />
				</div>
				<div className="">
					<label htmlFor="resume" className="mb-1 inline-block text-p5">Choose file</label>
					<input id="resume" name="resume" required={data?.info?.employment_resume_required ?? false} autoComplete="off" type="file" className="w-full rounded border border-neutral-300 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 dark:text-neutral-200 px-3 py-3 outline-none ring-primary-300 transition duration-100 focus:ring" />
				</div>
			</div>

			{/* Addiional questions */}
			<h3 className={`${title_styles}
				w-full mb-8 dark:text-white`}>

				Please answer the following questions

			</h3>

			<div className={`${columns_per_row['questions'][columns]}
			w-full grid gap-8 mb-12`}>

				{/* Drivers license */}
				{
					data?.info?.employment_ask_driver_license &&
					<div className="flex flex-wrap w-full mb-8 md:mb-0">

						<h4 className="w-full mb-2 text-h6 dark:text-white">
							Do you have a valid drivers license?*
						</h4>

						<div className="w-1/2 flex items-center">
							<RadioButton
								required={data?.info?.employment_ask_driver_license_required ?? false}
								id="drivers-license-yes"
								value="yes"
								name="driverLicense"
							/>
							<label
								htmlFor="drivers-license-yes"
								className="ms-2">
								Yes
							</label>
						</div>

						<div className="w-1/2 flex items-center">
							<RadioButton
								required={data?.info?.employment_ask_driver_license_required ?? false}
								id="drivers-license-no"
								value="no"
								name="driverLicense"
							/>
							<label
								htmlFor="drivers-license-no"
								className="ms-2">
								No
							</label>
						</div>

					</div>
				}

				{/* Own transportation */}
				{
					data?.info?.employment_ask_transportation &&
					<div className="flex flex-wrap w-full">

						<h4 className="w-full mb-2 text-h6 dark:text-white">
							Do you have your own transportation?*
						</h4>

						<div className="w-1/2 flex items-center">
							<RadioButton
								required={data?.info?.employment_ask_transportation_required ?? false}
								id="own-transportation-yes"
								value="yes"
								name="ownTransportation"
							/>
							<label
								htmlFor="own-transportation-yes"
								className="ms-2">
								Yes
							</label>
						</div>

						<div className="w-1/2 flex items-center">
							<RadioButton
								required={data?.info?.employment_ask_transportation_required ?? false}
								id="own-transportation-no"
								value="no"
								name="ownTransportation"
							/>
							<label
								htmlFor="own-transportation-no"
								className="ms-2">
								No
							</label>
						</div>
					</div>
				}

			</div>

			{/* Custom questions */}
			<ul className={`${columns_per_row['questions'][columns]}
			w-full grid place-items-start gap-8 mb-12`}>
				{
					data?.employment_questions?.map((question: employment_question, idx: number) => (
						question.enabled && question.employment_question_type_id &&
						<li
							key={question.sort}
							className="flex flex-wrap w-full">

							<label className="w-full">

								<h4 className="mb-2 text-h6 dark:text-white">
									{question.question}
								</h4>

								{question_type(question.employment_question_type_id, idx, question.options, question.required)}

							</label>
						</li>
					))
				}
			</ul>

			{/* Form button */}
			<div className="flex flex-wrap w-full items-center justify-between">

				<input type="hidden" name="shopId" value={process.env.SHOP_ID} />
				<input type="hidden" name="cb" value="harry" />
				<input type="hidden" name="gRecaptchaResponse" value="dummy" />

				<Context.Provider value={contextValue}>
					{contextHolder}
					<button type="submit" className="inline-flex items-center justify-center w-full md:w-auto rounded bg-primary-500 px-8 py-3 font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring active:bg-primary-500 cursor-pointer">
						Submit
						<FAIcon type="solid" nameIco='fa-envelop' customClass="h-4 text-white ml-2" />
					</button>
				</Context.Provider>

				<p className="text-p6 mt-8 md:mt-0">
					*Message and data rates may apply. Text STOP to opt-out or HELP for help.
				</p>

			</div>

		</form>
	)
}