"use client";

import FAIcon from "@/base-components/icons";
import { DsxImageClient } from "@/base-components/image";
import { shop } from "@/types/graphql";
import { ColorProperty, getColor } from "@/utils/color";
import { stdComponentDataList } from "@/utils/std-component-data";
import { ShopColors, DsxImageType } from "@/utils/types";
import Link from "next/link";
import { useEffect, useRef, useState } from "react";

//-->dynamicData
let dynamicData = {
	uid: 'legacy-template-6-special-message',
	adapter: "banner",
	data: {

		// UI
		"skew": {
			sort: 1,
			name: 'Skew',
			type: 'checkbox',
			value: 'true',
			section: 'ui',
		},
		"paddings": stdComponentDataList('slider', 2, 'Paddings', 'Padding', 'py', 6),
	}
}
//<--dynamicData

export default function Banner({ data, images, config }: { data: shop, images: DsxImageType, config: any }): React.ReactElement
{

	if (config && config.uid)
	{
		dynamicData = config;
	}

	const [isVisible, setIsVisible] = useState(true);
	const [height, setHeight] = useState<number | 'auto'>('auto');
	const contentRef = useRef<HTMLDivElement>(null);

	useEffect(() =>
	{
		if (contentRef.current)
		{
			setHeight(isVisible ? contentRef.current.scrollHeight : 0);
		}
	}, [isVisible]);

	const handleClose = () =>
	{
		setIsVisible(false);
	};

	const text_color: ShopColors = {
		primary: 'text-primary-500',
		secondary: 'text-secondary-500',
		neutral: 'text-neutral-900',
		unset: 'text-white',
	}

	const button_colors: ShopColors = {
		primary: 'bg-primary-500 hover:bg-primary-600 active:bg-primary-600 text-white',
		secondary: 'bg-secondary-500 hover:bg-secondary-600 active:bg-secondary-600 text-white',
		neutral: 'bg-neutral-500 hover:bg-neutral-600 active:bg-neutral-600 text-white',
		unset: 'bg-white hover:bg-neutral-50 active:bg-neutral-50 text-primary-500'
	}

	const skew = dynamicData?.data?.skew?.value === 'true' && "-skew-x-[14deg] transform-gpu";
	const unskew = dynamicData?.data?.skew?.value === 'true' && "skew-x-[14deg] transform-gpu";

	return (
		<>
			<div
				style={{ height: typeof height === 'number' ? `${height}px` : height }}
				className={`overflow-hidden transition-[height] duration-300`}>

				<div
					ref={contentRef}
					className={getColor(
						ColorProperty.background,
						data?.info?.special_message_color ?? 'unset',
						500)}>

					<div className={`${dynamicData?.data?.paddings?.value ?? 'py-6'}
          		flex flex-wrap md:flex-nowrap px-4 sm:px-6 xl:px-0 py-4 mx-auto max-w-screen-xl items-center justify-between`}>

						<div className="flex w-full mb-4 md:mb-0 space-x-2 md:mr-4">

							{/* Icon */}
							{
								data?.info?.special_message_image_id &&
								<div className={getColor(
									ColorProperty.text,
									data?.info?.special_message_text_color ?? 'neutral',
									text_color)}>
									<DsxImageClient dsxImage={images} />
								</div>
							}

							{/* Content */}
							<div
								className="w-full line-clamp-3 md:line-clamp-2"
								dangerouslySetInnerHTML={{ __html: data?.info?.special_message_content ?? '' }}
							/>

						</div>

						<div className="flex w-full md:w-auto justify-between items-center">

							{/* Link */}
							{
								(data?.info?.special_message_button_text && data?.info?.special_message_button_url) &&
								<Link
									href={data?.info?.special_message_button_url ?? '/specials'}
									className={`${data?.info?.special_message_button_color && data?.info?.special_message_button_color.includes('custom')
										?
										getColor(
											ColorProperty.background,
											data?.info?.special_message_button_color ?? 'unset',
											button_colors) + ' text-white'
										:
										getColor(
											ColorProperty.background,
											data?.info?.special_message_button_color ?? 'unset',
											button_colors)
										} 
												${skew}
												inline-flex whitespace-nowrap text-center font-bold italic items-center rounded px-8 py-3 focus:outline-none focus:ring cursor-pointer`}>

									{data?.info?.special_message_button_text}

									<FAIcon type="solid" nameIco='fa-arrow-right' customClass="w-4 h-4 ml-2" />

								</Link>
							}

							{/* Close button */}
							{
								data?.info?.special_message_display_close_button &&
								<button
									onClick={handleClose}
									aria-label="Close button"
									className={`${getColor(
										ColorProperty.text,
										data?.info?.special_message_text_color ?? 'unset',
										text_color)} 
											p-2 leading-none	 active:border-none active:outline-none`}>

									<FAIcon type="solid" nameIco='fa-xmark' customClass="w-6 h-6" />

								</button>
							}
						</div>

					</div>

				</div>

			</div>
		</>
	)

}