"use client";

import { review } from "@/types/graphql";
import { stdComponentDataList } from "@/utils/std-component-data";
import { ShopColors } from "@/utils/types";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import FAIcon from "@/base-components/icons";
import Link from "next/link";
import 'swiper/css';
import 'swiper/css/pagination';
import { useEffect, useState } from 'react';
import { ColorProperty, getColor } from "@/utils/color";

//-->dynamicData
let dynamicData = {
	uid: 'legacy-reviews-carousel',
	adapter: "reviews",
	data: {

		// Content
		"title": {
			sort: 1,
			name: 'Title',
			type: 'text',
			value: 'What Our Customers Say About Us',
			section: 'content',
		},
		"button_text": {
			sort: 2,
			name: 'Button Text',
			type: 'text',
			value: 'Read Our Customers Reviews',
			section: 'content',
		},
		"button_link": {
			sort: 3,
			name: 'Button Link',
			type: 'text',
			value: '/reviews',
			section: 'content',
		},
		'reviews_number': {
			sort: 4,
			name: 'Reviews Number',
			type: 'number',
			value: 4,
			min_num: 1,
			max_num: 16,
			section: 'content'
		},
		'delay': {
			sort: 5,
			name: 'Delay',
			type: 'number',
			value: 12,
			min_num: 4,
			max_num: 32,
			section: 'content'
		},

		// UI
		"section_bg_color": {
			sort: 1,
			name: 'Section Background Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},
		"button_color": {
			sort: 2,
			name: 'Button Color',
			type: 'color',
			value: 'primary',
			section: 'ui',
		},
		"paddings": stdComponentDataList('slider', 3, 'Top and Bottom Paddings', 'Padding', 'py', 12),
	},
}
//<--dynamicData

export default function Reviews({ data, config }: any)
{

	if (config && config.uid)
	{
		dynamicData = config;
	}

	// Array of selected reviews
	const selected_reviews: review[] = data?.reviews?.filter((review: review) =>
	{
		return review.selected;
	});

	const [random_reviews, setRandomReviews] = useState<review[]>()

	useEffect(() =>
	{
		setRandomReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
		{
			return review.rating === 5;
		}).slice(0, 5));
	}, [data]);

	const section_bg_color: ShopColors = {
		primary: 'bg-primary-500 text-primary-50',
		secondary: 'bg-secondary-500 text-secondary-50',
		neutral: 'bg-neutral-500 text-neutral-50',
		unset: 'bg-white text-inherit'
	}

	const button_color: ShopColors = {
		primary: 'bg-primary-500 hover:bg-primary-600 active:bg-primary-600 text-primary-50',
		secondary: 'bg-secondary-500 hover:bg-secondary-600 active:bg-secondary-600 text-secondary-50',
		neutral: 'bg-neutral-500 hover:bg-neutral-600 active:bg-neutral-600 text-neutral-50',
		unset: 'bg-neutral-50 hover:bg-neutral-100 active:bg-neutral-100 text-primary-500 text-primary-500'
	}

	return (
		<section
			id={dynamicData?.uid}
			className={getColor(
				ColorProperty.background,
				dynamicData?.data?.section_bg_color?.value,
				section_bg_color)}>

			<div className={`${dynamicData?.data?.paddings?.value ?? 'py-12'} 
			px-4 sm:px-6 xl:px-0 mx-auto max-w-screen-xl`}>

				{/* Title */}
				<h2 className="mb-4 font-bold text-center capitalize tracking-tight">
					{dynamicData?.data?.title?.value ?? ''}
				</h2>

				<Swiper
					autoplay={{
						disableOnInteraction: false,
						delay: (dynamicData?.data?.delay?.value && dynamicData?.data?.delay?.value > 0
							? (dynamicData?.data?.delay?.value ?? 12) * 1000
							: 1000),
						pauseOnMouseEnter: true,
					}}
					modules={[Autoplay, Pagination]}
					pagination={{
						clickable: true,
					}}
					spaceBetween={0}
					slidesPerView={1}
					breakpoints={{
					}}
					loop={true}>

					<div className="swiper-wrapper relative">
						{
							selected_reviews?.length > 0
								? selected_reviews?.map((review: review, idx: number) =>
								{
									return (
										<SwiperSlide key={idx}>

											<div className="flex flex-col items-center justify-center mt-8">

												{/* Avatar */}
												<div className="flex items-center justify-center p-8 mb-4 rounded-full bg-neutral-200">
													<FAIcon type="solid" nameIco='fa-user' customClass="h-10 w-10" />
												</div>

												{/* Author */}
												<h3 className="text-xl font-bold capitalize tracking-tight mb-4">
													{review?.author_name ?? 'Unknown Author'}
												</h3>

												{/* Review text */}
												<p className="text-center mx-auto max-w-3xl mb-12 line-clamp-3">
													{review?.content ?? '—'}
												</p>

											</div>

										</SwiperSlide>
									)
								})
								: random_reviews?.map((review: review, idx: number) =>
								{
									return (
										<SwiperSlide key={idx}>

											<div className="flex flex-col items-center justify-center mt-8">

												{/* Avatar */}
												<div className="flex items-center justify-center p-8 mb-4 rounded-full bg-neutral-200">
													<FAIcon type="solid" nameIco='fa-user' customClass="h-10 w-10" />
												</div>

												{/* Author */}
												<h3 className="text-xl font-bold capitalize tracking-tight mb-4">
													{review?.author_name ?? 'Unknown Author'}
												</h3>

												{/* Review text */}
												<p className="text-center mx-auto max-w-3xl mb-12 line-clamp-3">
													{review?.content ?? '—'}
												</p>

											</div>

										</SwiperSlide>
									)
								})
						}
					</div>

				</Swiper>

				{/* CTA */}
				<div className="mt-8 flex flex-wrap text-center">
					<Link
						className={`${dynamicData?.data?.button_color?.value && dynamicData?.data?.button_color?.value.includes('custom')
							?
							getColor(
								ColorProperty.background,
								dynamicData?.data?.button_color?.value ?? 'unset',
								button_color) + ' text-white'
							:
							getColor(
								ColorProperty.background,
								dynamicData?.data?.button_color?.value ?? 'unset',
								button_color)
							} mx-auto inline-flex items-center rounded px-8 py-3 font-medium focus:outline-none focus:ring cursor-pointer`}

						href={dynamicData?.data?.button_link?.value ?? '/reviews'}>

						{dynamicData?.data?.button_text?.value ?? 'Read Our Customers Reviews'}

						<FAIcon type="solid" nameIco='fa-arrow-right' customClass="h-4 ml-2" />

					</Link>
				</div>

			</div>

		</section>
	)
}