'use client';

import { review, shop } from "@/types/graphql";
import Rating from "@/ui-components/rating";
import { getColor, ColorProperty } from "@/utils/color";
import { stdComponentDataList } from "@/utils/std-component-data";
import { ShopColors } from "@/utils/types";
import Link from "next/link";
import { useEffect, useState } from "react";

//-->dynamicData
let dynamicData = {
  uid: "legacy-template-4-reviews",
  adapter: "reviews",
  data: {

    // Content
    "title": {
      sort: 1,
      name: 'Title',
      type: 'text',
      value: 'Our Reviews',
      section: 'content',
    },
    "reviews_number": {
      sort: 2,
      name: 'Reviews Number',
      type: 'radio',
      value: 3,
      options: [
        {
          'display': "3",
          'value': 3,
        },
        {
          'display': "6",
          'value': 6,
        },
      ],
      section: 'content'
    },
    "primary_link_text": {
      sort: 3,
      name: 'Primary Link Text',
      type: 'text',
      value: 'Request Appointment',
      section: 'content',
    },
    "primary_link": {
      sort: 4,
      name: 'Primary Link',
      type: 'text',
      value: '#make-appointment',
      section: 'content',
    },
    "secondary_link_text": {
      sort: 5,
      name: 'Secondary Link Text',
      type: 'text',
      value: 'More From Our Customers',
      section: 'content',
    },
    "secondary_link": {
      sort: 6,
      name: 'Secondary Link',
      type: 'text',
      value: 'reviews',
      section: 'content',
    },

    // UI
    "section_bg_color": {
      sort: 1,
      name: 'Section Background Color',
      type: 'color',
      value: 'neutral',
      section: 'ui',
    },
    "section_bg_color_tint": stdComponentDataList('slider', 2, 'Section Background Color Tint', 'Section Background Color Tint', '', 500, 'ui', 'tints'),
    "section_text_color": {
      sort: 3,
      name: 'Section Text Color',
      type: 'color',
      value: 'unset',
      section: 'ui',
    },
    "section_text_color_tint": stdComponentDataList('slider', 4, 'Section Text Color Tint', 'Section Background Color Tint', '', 600, 'ui', 'tints'),

    "review_bg_color": {
      sort: 5,
      name: 'Review Background Color',
      type: 'color',
      value: 'unset',
      section: 'ui',
    },
    "review_bg_color_tint": stdComponentDataList('slider', 6, 'Review Background Color Tint', 'Review Color Tint', '', 50, 'ui', 'tints'),
    "review_text_color": {
      sort: 7,
      name: 'Review Text Color',
      type: 'color',
      value: 'neutral',
      section: 'ui',
    },
    "review_text_color_tint": stdComponentDataList('slider', 8, 'Review Text Color Tint', 'Review Color Tint', '', 600, 'ui', 'tints'),
    "review_shadow": {
      sort: 9,
      name: 'Review Shadow',
      type: 'checkbox',
      value: 'true',
      section: 'ui',
    },

    "primary_link_color": {
      sort: 10,
      name: 'Primary Link Color',
      type: 'color',
      value: 'primary',
      section: 'ui',
    },

    "paddings": stdComponentDataList('slider', 11, 'Top and Bottom Paddings', 'Padding', 'py', 20),
  },
};
//<--dynamicData

export default function Reviews({ data, config }: { data: shop, config: any })
{

  if (config && config.uid)
  {
    dynamicData = config;
  }

  const primary_button_color: ShopColors = {
    primary: 'bg-primary-500 hover:bg-primary-600 active:bg-primary-600 text-white',
    secondary: 'bg-secondary-500 hover:bg-secondary-600 active:bg-secondary-600 text-white',
    neutral: 'bg-neutral-500 hover:bg-neutral-600 active:bg-neutral-600 text-white',
    unset: 'bg-white hover:bg-primary-50 active:bg-primary-50 text-primary-500 hover:text-primary-600',
    custom: 'text-white',
  }

  const secondary_button_color: ShopColors = {
    primary: 'text-primary-500 hover:text-primary-600 active:text-primary-600 border border-neutral-200 hover:border-neutral-300 dark:border-neutral-600 dark:hover:border-neutral-500',
    secondary: 'text-secondary-500 hover:text-secondary-600 active:text-secondary-600 border border-neutral-200 hover:border-neutral-300 dark:border-neutral-600 dark:hover:border-neutral-500',
    neutral: 'text-neutral-500 hover:text-neutral-600 active:text-neutral-600 border border-neutral-200 hover:border-neutral-300 dark:border-neutral-600 dark:hover:border-neutral-500',
    unset: 'text-neutral-500 hover:text-neutral-600 active:text-neutral-600 border border-neutral-200 hover:border-neutral-300 dark:border-neutral-600 dark:hover:border-neutral-500',
    custom: 'border border-neutral-200 hover:border-neutral-300 dark:border-neutral-600 dark:hover:border-neutral-500',
  }

  const review_shadow: any = dynamicData?.data?.review_shadow?.value === 'true' && 'shadow hover:shadow-md'

  // Array of selected reviews
  const selected_reviews: review[] = data?.reviews?.filter((review: review) =>
  {
    return review.selected;
  });

  // Array with random Google reviews with 5* rating. Used by default
  const [random_google_reviews, setRandomGoogleReviews] = useState<review[]>()
  useEffect(() =>
  {
    setRandomGoogleReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
    {
      return review.rating === 5 && review.source_id === 1;
    }).slice(0, dynamicData?.data?.reviews_number?.value));
  }, [data]);

  // Array with random AV reviews with 5* rating. Used in case shop has no Google reviews 
  const [random_av_reviews, setAVReviews] = useState<review[]>()
  useEffect(() =>
  {
    setAVReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
    {
      return review.rating === 5 && review.source_id === 2;
    }).slice(0, dynamicData?.data?.reviews_number?.value));
  }, [data]);

  const reviews: review[] = selected_reviews && selected_reviews?.length > 0
    ?
    selected_reviews
    :
    random_google_reviews && random_google_reviews?.length > 0
      ?
      random_google_reviews
      :
      random_av_reviews && random_av_reviews?.length > 0
        ?
        random_av_reviews
        :
        []

  return (
    <section
      id={dynamicData?.uid}
      className={`
        ${getColor(
        ColorProperty.background,
        dynamicData?.data?.section_bg_color?.value,
        dynamicData?.data?.section_bg_color_tint?.value)}`}>

      <div className={`${dynamicData?.data?.paddings?.value ?? 'py-12'}
      mx-auto max-w-screen-xl px-4 md:px-6 lg:px-8 xl:px-0`}>

        {/* Title */}
        <h2 className={`${getColor(
          ColorProperty.text,
          dynamicData?.data?.section_text_color?.value,
          Number(dynamicData?.data?.section_text_color_tint?.value) + 300)}
          text-h3 lg:text-d2 font-black uppercase tracking-wide mb-8`}>

          {dynamicData?.data?.title?.value ?? ''}

        </h2>

        <ul className="grid gap-x-8 gap-y-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mb-12">
          {
            reviews?.map((review: review, idx: number) => (

              <li
                key={idx}
                className={`
                  ${getColor(
                  ColorProperty.background,
                  dynamicData?.data?.review_bg_color?.value,
                  dynamicData?.data?.review_bg_color_tint?.value)}
                  relative flex h-full flex-col justify-between px-6 py-8 rounded`}>

                {/* Background */}
                <div className={`${getColor(
                  ColorProperty.background,
                  dynamicData?.data?.review_bg_color?.value,
                  dynamicData?.data?.review_bg_color_tint?.value)}
									absolute flex inset-0 rounded`} />

                {/* Shadow */}
                {
                  dynamicData?.data?.review_shadow?.value === 'true' &&
                  <div className={`absolute flex inset-0 rounded shadow hover:shadow-md duration-300 mix-blend-overlay`} />
                }

                <div className="relative mb-2 md:mb-6">

                  <Rating rating={review?.rating ?? 5} color={''} />

                  <div className="mt-4">
                    <p className="mb-2 line-clamp-5">

                      {review?.content ?? '—'}

                    </p>
                  </div>

                </div>

                <footer className="relative capitalize text-right font-bold">

                  {/* Author */}
                  {review?.author_name?.toLowerCase() ?? "Unknown Author"}

                  {/* Location */}
                  {
                    review?.location &&
                    <span className="font-normal">

                      {' from ' + review?.location}

                    </span>
                  }

                </footer>

              </li>

            ))
          }
        </ul>

        <div className="flex flex-wrap w-full space-y-4 md:space-y-0 md:space-x-4 md:w-auto mb-4 md:mb-0 md:mr-4 text-center">

          {/* Primary button */}
          {
            dynamicData?.data?.primary_link_text?.value &&
            <Link
              id={dynamicData.uid + '-primary-button'}
              href={dynamicData?.data?.primary_link?.value ?? '#make-appointment'}
              className={`${getColor(
                ColorProperty.background,
                dynamicData?.data?.primary_link_color?.value,
                primary_button_color)} 
								leading-none inline-flex w-full md:w-auto justify-center items-center rounded px-8 py-4 font-bold uppercase duration-300 focus:outline-none focus:ring sm:w-auto cursor-pointer`}>

              {dynamicData?.data?.primary_link_text?.value}

            </Link>
          }

          {/* Secondary button */}
          {
            dynamicData?.data?.secondary_link_text?.value &&
            <Link
              id={dynamicData.uid + '-secondary-button'}
              href={dynamicData?.data?.secondary_link?.value ?? '#make-appointment'}
              className={`${getColor(
                ColorProperty.text,
                dynamicData?.data?.primary_link_color?.value,
                secondary_button_color)} 
								leading-none inline-flex w-full md:w-auto justify-center items-center rounded px-8 py-4 font-bold uppercase duration-300 focus:outline-none focus:ring sm:w-auto cursor-pointer`}>

              {dynamicData?.data?.secondary_link_text?.value}

            </Link>
          }

        </div>

      </div>

    </section>

  )
}
