import FAIcon from "@/base-components/icons";

interface RatingProps
{
	rating: number;
	color: string;
	className?: string;
}

export default function Rating({ rating, color, className = '' }: RatingProps): React.ReactElement
{

	const _color = color || 'text-[#FEAF00]';

	const stars = (rating: number) =>
		<div className={`${className} flex leading-none`}>
			{
				[...Array(rating)].map((_, index) => (
					<FAIcon key={index} type="solid" nameIco='fa-star' customClass={`${_color} h-4`} />
				))
			}
		</div>

	function setStars()
	{
		switch (rating)
		{
			case 1:
				return stars(rating);

			case 2:
				return stars(rating);

			case 3:
				return stars(rating);

			case 4:
				return stars(rating);

			case 5:
				return stars(rating);

			default:
				return stars(rating);
		}
	}

	return (
		<>
			{setStars()}
		</>
	);
}
