'use client';

import { review, shop } from "@/types/graphql";
import Rating from "@/ui-components/rating";
import { getColor, ColorProperty } from "@/utils/color";
import { stdComponentDataList } from "@/utils/std-component-data";
import { ShopColors } from "@/utils/types";
import Link from "next/link";
import { useEffect, useState } from "react";

//-->dynamicData
let dynamicData = {
	uid: "legacy-template-6-reviews",
	adapter: "reviews",
	data: {

		// Content
		"title": {
			sort: 1,
			name: 'Title',
			type: 'text',
			value: 'Our Reviews',
			section: 'content',
		},
		"reviews_number": {
			sort: 2,
			name: 'Reviews Number',
			type: 'radio',
			value: 3,
			options: [
				{
					'display': "3",
					'value': 3,
				},
				{
					'display': "6",
					'value': 6,
				},
			],
			section: 'content'
		},
		
		"primary_link_text": {
			sort: 3,
			name: 'Primary Link Text',
			type: 'text',
			value: 'Request Appointment',
			section: 'content',
		},
		"primary_link": {
			sort: 4,
			name: 'Primary Link',
			type: 'text',
			value: '#make-appointment',
			section: 'content',
		},
		"secondary_link_text": {
			sort: 5,
			name: 'Secondary Link Text',
			type: 'text',
			value: 'More From Our Customers',
			section: 'content',
		},
		"secondary_link": {
			sort: 6,
			name: 'Secondary Link',
			type: 'text',
			value: 'reviews',
			section: 'content',
		},

		// UI
		"section_bg_color": {
			sort: 1,
			name: 'Section Background Color',
			type: 'color',
			value: 'primary',
			section: 'ui',
		},
		"section_bg_color_tint": stdComponentDataList('slider', 2, 'Section Background Color Tint', 'Section Background Color Tint', '', 500, 'ui', 'tints'),
		"section_text_color": {
			sort: 3,
			name: 'Section Text Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},
		"section_text_color_tint": stdComponentDataList('slider', 4, 'Section Text Color Tint', 'Section Background Color Tint', '', 500, 'ui', 'tints'),

		"skew": {
			sort: 5,
			name: 'Skew',
			type: 'checkbox',
			value: 'true',
			section: 'ui',
		},

		"review_bg_color": {
			sort: 6,
			name: 'Review Background Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},
		"review_bg_color_tint": stdComponentDataList('slider', 7, 'Review Background Color Tint', 'Review Color Tint', '', 50, 'ui', 'tints'),
		"review_text_color": {
			sort: 8,
			name: 'Review Text Color',
			type: 'color',
			value: 'neutral',
			section: 'ui',
		},
		"review_text_color_tint": stdComponentDataList('slider', 9, 'Review Text Color Tint', 'Review Color Tint', '', 600, 'ui', 'tints'),
		"review_shadow": {
			sort: 10,
			name: 'Review Shadow',
			type: 'checkbox',
			value: 'false',
			section: 'ui',
		},

		"primary_link_color": {
			sort: 11,
			name: 'Primary Link Color',
			type: 'color',
			value: 'unset',
			section: 'ui',
		},

		"padding_top": stdComponentDataList('slider', 12, 'Top Padding', 'Top Padding', 'pt', 20),
		"padding_bottom": stdComponentDataList('slider', 13, 'Bottom Padding', 'Bottom Padding', 'pb', 20),
	},
};
//<--dynamicData

export default function Reviews({ data, config }: { data: shop, config: any })
{

	if (config && config.uid)
	{
		dynamicData = config;
	}

	const primary_link_color: ShopColors = {
		primary: 'bg-primary-500 border border-primary-500 hover:bg-primary-600 active:bg-primary-600 text-white',
		secondary: 'bg-secondary-500 border border-secondary-500 hover:bg-secondary-600 active:bg-secondary-600 text-white',
		neutral: 'bg-neutral-500 border border-neutral-500 hover:bg-neutral-600 active:bg-neutral-600 text-white',
		unset: 'bg-white border border-white hover:bg-primary-100 active:bg-primary-100 text-primary-500',
		custom: 'text-white',
	}

	const secondary_link_color: ShopColors = {
		primary: 'bg-white border border-white text-primary-500 hover:text-primary-600 hover:bg-primary-50',
		secondary: 'bg-white border border-white text-secondary-500 hover:text-secondary-600 hover:bg-secondary-50',
		neutral: 'bg-white border border-white text-neutral-500 hover:text-neutral-600 border hover:bg-neutral-50',
		unset: 'text-white border border-white hover:text-primary-500 hover:border-primary-500',
		custom: ' ',
	}

	const skew = dynamicData?.data?.skew?.value === 'true' && "-skew-x-[14deg] transform-gpu";
	const unskew = dynamicData?.data?.skew?.value === 'true' && "skew-x-[14deg] transform-gpu";

	// Array of selected reviews
	const selected_reviews: review[] = data?.reviews?.filter((review: review) =>
	{
		return review.selected;
	});

	// Array with random Google reviews with 5* rating. Used by default
	const [random_google_reviews, setRandomGoogleReviews] = useState<review[]>()
	useEffect(() =>
	{
		setRandomGoogleReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
		{
			return review.rating === 5 && review.source_id === 1;
		}).slice(0, dynamicData?.data?.reviews_number?.value));
	}, [data]);

	// Array with random AV reviews with 5* rating. Used in case shop has no Google reviews 
	const [random_av_reviews, setAVReviews] = useState<review[]>()
	useEffect(() =>
	{
		setAVReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
		{
			return review.rating === 5 && review.source_id === 2;
		}).slice(0, dynamicData?.data?.reviews_number?.value));
	}, [data]);

	const reviews: review[] = selected_reviews && selected_reviews?.length > 0
		?
		selected_reviews
		:
		random_google_reviews && random_google_reviews?.length > 0
			?
			random_google_reviews
			:
			random_av_reviews && random_av_reviews?.length > 0
				?
				random_av_reviews
				:
				[]

	return (
		<section
			id={dynamicData?.uid}
			className={`
				${getColor(
				ColorProperty.background,
				dynamicData?.data?.section_bg_color?.value,
				dynamicData?.data?.section_bg_color_tint?.value)}
				overflow-hidden`}>

			<div className={`
			${dynamicData?.data?.padding_top?.value ?? 'py-20'} 
			${dynamicData?.data?.padding_bottom?.value ?? 'pb-20'} 
			mx-auto max-w-screen-xl px-4 md:px-6 lg:px-8 xl:px-0`}>

				{/* Header */}
				<div className={`flex flex-wrap xl:flex-nowrap justify-between w-full`}>

					{/* Content */}
					<div className="max-w-screen-md space-y-4 pb-8">

						{/* Title */}
						{
							dynamicData?.data?.title?.value &&
							<h2 className={`${getColor(
								ColorProperty.text,
								dynamicData?.data?.section_text_color?.value,
								Number(dynamicData?.data?.section_text_color_tint?.value) + 300)} 
								font-bold capitalize italic tracking-tight`}>

								{dynamicData?.data?.title?.value}

							</h2>
						}

					</div>

					{/* Links */}
					<div className={`flex flex-wrap md:flex-nowrap items-start w-full lg:w-auto mx-4 md:mx-0 space-y-4 md:space-y-0 md:space-x-4`}>

						{/* Primary link */}
						{
							dynamicData?.data?.primary_link_text?.value &&
							<Link
								href={dynamicData?.data?.primary_link?.value ?? '#make-appointment'}
								className={`${getColor(
									ColorProperty.background,
									dynamicData?.data?.primary_link_color?.value,
									primary_link_color)} 
									${skew} 
									inline-flex w-full sm:w-auto text-center justify-center items-center rounded-xs px-8 py-4 font-bold italic uppercase whitespace-nowrap duration-300 focus:outline-none focus:ring cursor-pointer`}>

								<span className={`${unskew}`}>
									{dynamicData?.data?.primary_link_text?.value ?? 'Request Appointment'}
								</span>

							</Link>
						}

						{/* Secondary button */}
						{
							dynamicData?.data?.secondary_link_text?.value &&
							<Link
								href={dynamicData?.data?.secondary_link?.value ?? '#make-appointment'}
								className={`${getColor(
									ColorProperty.border,
									dynamicData?.data?.primary_link_color?.value,
									secondary_link_color)} 
									${skew} 
									inline-flex w-full sm:w-auto text-center justify-center items-center rounded-xs px-8 py-4 font-bold italic uppercase whitespace-nowrap duration-300 focus:outline-none focus:ring cursor-pointer`}>

								<span className={`${unskew}`}>
									{dynamicData?.data?.secondary_link_text?.value ?? 'Request Appointment'}
								</span>

							</Link>
						}

					</div>

				</div>

				{/* Reviews */}
				<ul className="grid gap-x-8 gap-y-12 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 py-12">
					{
						reviews?.map((review: review, idx: number) => (

							<li
								key={idx}
								className={`
									${getColor(
									ColorProperty.text,
									dynamicData?.data?.review_text_color?.value,
									dynamicData?.data?.review_text_color_tint?.value)} 
									relative flex flex-col justify-between h-full px-2 py-8 rounded`}>

								{/* Background */}
								<div className={`
									${skew}
									${getColor(
									ColorProperty.background,
									dynamicData?.data?.review_bg_color?.value,
									dynamicData?.data?.review_bg_color_tint?.value)}
									absolute flex inset-0 rounded`} />

								{/* Shadow */}
								{
									dynamicData?.data?.review_shadow?.value === 'true' &&
									<div className={`${skew}
										absolute flex inset-0 rounded shadow hover:shadow-md duration-300 mix-blend-overlay`} />
								}

								<div className={`relative`}>

									<Rating rating={review?.rating ?? 5} color={''} className={`${skew} ml-12 mb-2`} />

									<div className="relative overflow-hidden">

										<style jsx global>
											{`
												.shape-custom-left {
													shape-outside: polygon(0% 0%, 100% 0%, 0% 100%);
													clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
												}
												.shape-custom-right {
													shape-outside: polygon(0% 100%, 100% 0%, 100% 100%);
													clip-path: polygon(0% 100%, 100% 0%, 100% 100%);
												}
											`}
										</style>

										<div className={`shape-custom-left relative float-left w-10 h-40`} />
										<div className={`shape-custom-right relative float-right w-10 h-40`} />
										<div className={`${skew}
											absolute w-11/12 h-1/4 bottom-0 bg-gradient-to-t from-white to-transparent`} />

										<p className={`h-40 italic mb-4`}>

											{review?.content ?? '—'}

										</p>

									</div>


									<footer className={`capitalize text-right font-bold italic mr-10`}>

										{/* Author */}
										{review?.author_name?.toLowerCase() ?? "Unknown Author"}

										{/* Location */}
										{
											review?.location &&
											<span className="font-normal">

												{' from ' + review?.location}

											</span>
										}

									</footer>

								</div>

							</li>

						))
					}
				</ul>

			</div>

		</section>

	)
}
