'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import { review } from "@/types/graphql";
import Rating from "@/ui-components/rating";
import { stdComponentDataList } from "@/utils/std-component-data";
import { useState, useEffect } from "react";
import { ColorProperty, getColor } from "@/utils/color";
import FAIcon from "@/base-components/icons";
import Link from "next/link";
import { ShopColors } from "@/utils/types";

//-->dynamicData
let dynamicData = {
  uid: 'reviews-slider',
  adapter: "reviews",
  data: {

    // Content
    "overtitle": {
      sort: 1,
      name: 'Overtitle',
      type: 'text',
      value: 'What Customers Say',
      section: 'content',
    },
    "title": {
      sort: 2,
      name: 'Title',
      type: 'text',
      value: 'Reviews from Customers in [County] County',
      section: 'content',
    },
    "description": {
      sort: 3,
      name: 'Description',
      type: 'textarea',
      value: 'Here is a few of their latest reviews',
      section: 'content',
    },

    "single_review": {
      sort: 4,
      name: 'Single Review on Desktop and Tablet Layouts',
      type: 'checkbox',
      value: 'false',
      section: 'content',
    },
    "reviews_number": {
      sort: 5,
      name: 'Reviews Number',
      type: 'number',
      value: 8,
      min_num: 3,
      max_num: 16,
      section: 'content'
    },
    "top_rating": {
      sort: 6,
      name: 'With Top Rating Only',
      type: 'checkbox',
      value: 'true',
      section: 'content',
    },
    "delay": {
      sort: 7,
      name: 'Delay',
      type: 'number',
      value: 12,
      min_num: 4,
      max_num: 32,
      section: 'content'
    },

    "primary_button_text": {
      sort: 8,
      name: 'Primary Button Text',
      type: 'text',
      value: 'Request Appointment',
      section: 'content',
    },
    "primary_button_link": {
      sort: 9,
      name: 'Primary Button Link',
      type: 'text',
      value: '#make-appointment',
      section: 'content',
    },
    "secondary_button_text": {
      sort: 10,
      name: 'Secondary Button Text',
      type: 'text',
      value: 'More From Our Customers',
      section: 'content',
    },
    "secondary_button_link": {
      sort: 11,
      name: 'Secondary Button Link',
      type: 'text',
      value: 'reviews',
      section: 'content',
    },

    // UI
    "section_bg_color": {
      sort: 1,
      name: 'Section Background Color',
      type: 'color',
      value: 'unset',
      section: 'ui',
    },
    "section_bg_color_tint": stdComponentDataList('slider', 2, 'Section Background Color Tint', 'Section Background Color Tint', '', 500, 'ui', 'tints'),

    "primary_button_color": {
      sort: 3,
      name: 'Button Color',
      type: 'color',
      value: 'primary',
      section: 'ui',
    },

    "content_align": {
      sort: 4,
      name: 'Content Align',
      type: 'radio',
      value: 'left',
      options: [
        {
          'display': "Left",
          'value': 'left'
        },
        {
          'display': "Center",
          'value': 'center'
        },
      ],
      section: 'ui'
    },
    "controls_position": {
      sort: 5,
      name: 'Controls Position on Desktop and Tablet Layouts',
      type: 'radio',
      value: 'header',
      options: [
        {
          'display': "Header",
          'value': 'header'
        },
        {
          'display': "Aside",
          'value': 'aside'
        },
      ],
      section: 'ui'
    },

    "review_style": {
      sort: 6,
      name: 'Review Style',
      type: 'radio',
      value: 'filled',
      options: [
        {
          'display': "Outlined",
          'value': 'outlined'
        },
        {
          'display': "Filled",
          'value': 'filled'
        }
      ],
      section: 'ui'
    },
    "review_color": {
      sort: 7,
      name: 'Review Color',
      type: 'color',
      value: 'unset',
      section: 'ui',
    },
    "review_color_tint": stdComponentDataList('slider', 8, 'Review Color Tint', 'Review Color Tint', '', 50, 'ui', 'tints'),
    "review_shadow": {
      sort: 9,
      name: 'Review Shadow',
      type: 'checkbox',
      value: 'true',
      section: 'ui',
    },

    "padding_top": stdComponentDataList('slider', 10, 'Top Padding', 'Top Padding', 'pt', 20),
    "padding_bottom": stdComponentDataList('slider', 11, 'Bottom Padding', 'Bottom Padding', 'pb', 20),
  },
}
//<--dynamicData

export default function Reviews({ data, config }: any)
{

  if (config && config.uid)
  {
    dynamicData = config;
  }

  const content_align: any = {
    // Single review
    'true': {
      // Content position
      left: 'flex-col justify-between text-left',
      center: 'flex-col items-center text-center flex-wrap',
    },
    // Multiple reviews
    'false': {
      // Content position
      left: 'justify-between items-end text-left',
      center: 'justify-center text-center flex-wrap',
    },
  }

  const review_paadings: any = {
    // Single review
    'true': {
      // Review has shadow
      'true': {
        // Controls position
        header: '-mx-4 md:mx-0',
        aside: '-mx-4 md:mx-12',
      },
      // Review has no shadow
      'false': {
        // Controls position
        header: '-mr-4 md:mr-0',
        aside: '-mr-4 md:mx-20',
      },
    },
    // Multiple reviews
    'false': {
      // Reviews have shadow
      'true': {
        // Controls position
        header: '-mx-4 md:-mx-6 lg:-mx-12 -mt-6',
        aside: '-mx-4 md:mx-12 lg:-mx-12',
      },
      // Reviews have no shadow
      'false': {
        // Controls position
        header: '-mr-4 md:-mr-6 lg:mr-0',
        aside: '-mr-4 md:mx-20 lg:mx-0',
      },
    },
  }

  const review_style: any = {
    filled: getColor(
      ColorProperty.background,
      dynamicData?.data?.review_color?.value,
      dynamicData?.data?.review_color_tint?.value) + ' dark:bg-neutral-800',
    outlined: getColor(
      ColorProperty.border,
      dynamicData?.data?.review_color?.value,
      dynamicData?.data?.review_color_tint?.value) + ' border dark:border-neutral-800',
  }

  const review_shadow: any = {
    'true': 'shadow',
    'false': '',
  }

  const primary_button_color: ShopColors = {
    primary: 'bg-primary-500 hover:bg-primary-600 active:bg-primary-700 text-white',
    secondary: 'bg-secondary-500 hover:bg-secondary-600 active:bg-secondary-700 text-white',
    neutral: 'bg-neutral-500 hover:bg-neutral-600 active:bg-neutral-700 text-white',
    unset: 'bg-white hover:bg-primary-100 active:bg-primary-200 text-primary-500',
    custom: 'text-white',
  }

  const secondary_button_color: ShopColors = {
    primary: 'text-primary-500 hover:text-primary-600 border border-neutral-300 hover:border-neutral-400 hover:bg-neutral-50',
    secondary: 'text-secondary-500 hover:text-secondary-600 border border-neutral-300 hover:border-neutral-400 hover:bg-neutral-50',
    neutral: 'text-neutral-500 hover:text-neutral-600 border border-neutral-300 hover:border-neutral-400 hover:bg-neutral-50',
    unset: 'text-white hover:text-primary-100 border border-neutral-200 hover:border-neutral-50',
    custom: ' ',
  }

  const buttons_align: any = {
    left: 'justify-start',
    center: 'justify-center',
  }

  function Review({ review }: { review: review })
  {
    return (
      <blockquote className={`
        // Review color and style
        ${review_style[dynamicData?.data?.review_style?.value]} 
        // Shadow
        ${review_shadow[dynamicData?.data?.review_shadow?.value]}
        // Align
        ${dynamicData?.data?.content_align?.value === 'center' && 'text-center lg:max-w-screen-md mx-auto'}
        flex flex-col justify-between h-72 px-6 py-8 rounded dark:bg-neutral-800`}>

        {/* Review text */}
        <p className="w-full line-clamp-5 dark:text-neutral-100">
          {review?.content ?? '—'}
        </p>

        {/* Content align left */}
        {
          dynamicData?.data?.content_align?.value === 'left' &&
          <div className={`flex justify-between items-end mt-4 w-full`}>

            <div className="">

              {/* Reviews */}
              {
                // Google review
                review.source_id === 2 &&
                <h3 className="text-h6 font-bold ml-auto capitalize antialiased line-clamp-1 dark:text-white">

                  {/* Author */}
                  {review?.author_name?.toLowerCase() ?? "Unknown Author"}

                </h3>
              }
              {
                // AV review
                review.source_id === 1 &&
                <h3 className="text-h6 font-bold leading-relaxed capitalize antialiased line-clamp-1 dark:text-white">

                  {/* Author */}
                  {review?.author_name?.toLowerCase() ?? "Unknown Author"}

                  {/* Location */}
                  <span className="font-normal">
                    {review?.location
                      ? ' from ' + review?.location
                      : ''}
                  </span>

                </h3>
              }

              {/* Date */}
              <p className="leading-none dark:text-neutral-200">
                {review?.date
                  ? ' on ' + new Date(review.date).toLocaleDateString('en-US')
                  : '—'}
              </p>

            </div>

            <Rating rating={review?.rating ?? 4} color={""} />

          </div>
        }

        {/* Content align center */}
        {
          dynamicData?.data?.content_align?.value === 'center' &&
          <div className={`flex justify-center items-end mt-4 w-full`}>

            <div className="">

              <Rating rating={review?.rating ?? 4} color={""} className={"justify-center mb-2"} />

              {/* Reviews */}
              {
                // Google review
                review.source_id === 1 &&
                <h3 className="w-full text-h6 font-bold ml-auto capitalize antialiased line-clamp-1 dark:text-white">

                  {/* Author */}
                  {review?.author_name?.toLowerCase() ?? "Unknown Author"}

                </h3>
              }
              {
                // AV review
                review.source_id === 2 &&
                <h3 className="w-full text-h6 font-bold leading-relaxed capitalize antialiased line-clamp-1 dark:text-white">

                  {/* Author */}
                  {review?.author_name?.toLowerCase() ?? "Unknown Author"}

                  {/* Location */}
                  <span className="font-normal">
                    {review?.location
                      ? ' from ' + review?.location
                      : ''}
                  </span>

                </h3>
              }

              {/* Date */}
              <p className="w-full leading-none dark:text-neutral-200">
                {review?.date
                  ? ' on ' + new Date(review.date).toLocaleDateString('en-US')
                  : '—'}
              </p>

            </div>

          </div>
        }


      </blockquote>
    )
  }

  // Array of selected reviews
  const selected_reviews: review[] = data?.reviews?.filter((review: review) =>
  {
    return review.selected;
  });

  // Array of random Google reviews with 5* rating. Used by default
  const [random_google_reviews, setRandomGoogleReviews] = useState<review[]>()
  useEffect(() =>
  {
    setRandomGoogleReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
    {
      return review.rating === 5 && review.source_id === 1;
    }).slice(0, dynamicData?.data?.reviews_number?.value));
  }, [data]);

  // Array of random AV reviews with 5* rating. Used in case shop has no Google reviews 
  const [random_av_reviews, setAVReviews] = useState<review[]>()
  useEffect(() =>
  {
    setAVReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
    {
      return review.rating === 5 && review.source_id === 2;
    }).slice(0, dynamicData?.data?.reviews_number?.value));
  }, [data]);

  const reviews: review[] = selected_reviews && selected_reviews?.length > 0
    ?
    selected_reviews
    :
    random_google_reviews && random_google_reviews?.length > 0
      ?
      random_google_reviews
      :
      random_av_reviews && random_av_reviews?.length > 0
        ?
        random_av_reviews
        :
        []

  return (
    <section className={`${getColor(
      ColorProperty.background,
      dynamicData?.data?.section_bg_color?.value,
      dynamicData?.data?.section_bg_color_tint?.value)} dark:bg-neutral-900`}>

      <div className={`
      ${dynamicData?.data?.padding_top?.value ?? 'py-20'} 
      ${dynamicData?.data?.padding_bottom?.value ?? 'pb-20'} 
      mx-auto max-w-screen-xl px-4 md:px-6 lg:px-8 xl:px-0`}>

        <div className={`${dynamicData?.data?.single_review?.value === 'true' && dynamicData?.data?.content_align?.value === 'left'
          ? 'grid-cols-1 lg:grid-cols-2'
          : 'grid-cols-1'} grid gap-8`}>

          <div className={`
          ${content_align[dynamicData?.data?.single_review?.value][dynamicData?.data?.content_align?.value]} 
          ${dynamicData?.data?.review_shadow?.value === 'true' && dynamicData?.data?.single_review?.value === 'true' && dynamicData?.data?.content_align?.value === 'left' && 'lg:pb-16'}
          flex w-full`}>

            {/* Header */}
            <div className="max-w-screen-md space-y-4">

              {/* Overtitle */}
              {
                dynamicData?.data?.overtitle?.value &&
                <p className="text-p6 w-full font-bold text-primary-500 uppercase tracking-wider line-clamp-1">
                  {dynamicData?.data?.overtitle?.value}
                </p>
              }

              {/* Title */}
              {
                dynamicData?.data?.title?.value &&
                <h2 className="font-bold capitalize tracking-tight lg:line-clamp-2 dark:text-white">
                  {dynamicData?.data?.title?.value}
                </h2>
              }

              {/* Description */}
              <p className="text-p2 lg:line-clamp-5 dark:text-neutral-100">
                {dynamicData?.data?.description?.value}
              </p>

            </div>

            {/* Controls header */}
            {
              dynamicData?.data?.controls_position?.value === 'header' && dynamicData?.data?.content_align?.value === 'left' &&
              <div className="hidden md:flex mt-4">

                <button className="prev-button rounded-full px-6 py-3 text-primary-500 hover:text-primary-600 leading-none">
                  <span className="sr-only">Previous Slide</span>
                  <FAIcon type="solid" nameIco='fa-arrow-left-long' customClass="" />
                </button>

                <button className="next-button rounded-full px-6 py-3 bg-primary-500 hover:bg-primary-600 text-white leading-none">
                  <span className="sr-only">Next Slide</span>
                  <FAIcon type="solid" nameIco='fa-arrow-right-long' customClass="" />
                </button>

              </div>
            }
            {
              dynamicData?.data?.controls_position?.value === 'header' && dynamicData?.data?.content_align?.value === 'center' &&
              <div className="hidden md:flex w-full justify-center mt-4">

                <button className="prev-button rounded-full px-6 py-3 text-primary-500 hover:text-primary-600 leading-none">
                  <span className="sr-only">Previous Slide</span>
                  <FAIcon type="solid" nameIco='fa-arrow-left-long' customClass="" />
                </button>

                <button className="next-button rounded-full px-6 py-3 bg-primary-500 hover:bg-primary-600 text-white leading-none">
                  <span className="sr-only">Next Slide</span>
                  <FAIcon type="solid" nameIco='fa-arrow-right-long' customClass="" />
                </button>

              </div>
            }

          </div>

          {/* Slider */}
          <div className={`${review_paadings[dynamicData?.data?.single_review?.value][dynamicData?.data?.review_shadow?.value][dynamicData?.data?.controls_position?.value]}
          relative`}>

            <Swiper
              autoplay={{
                disableOnInteraction: false,
                delay: dynamicData?.data?.delay?.value > 0
                  ? (dynamicData?.data?.delay?.value ?? 12) * 1000
                  : 12000,
                pauseOnMouseEnter: true,
              }}
              navigation={{
                nextEl: '.next-button',
                prevEl: '.prev-button',
              }}
              modules={[Autoplay, Navigation]}
              spaceBetween={
                dynamicData?.data?.review_shadow?.value === 'true'
                  ? -12
                  : 12
              }
              slidesPerView={1.2}
              breakpoints={{
                640: {
                  spaceBetween: dynamicData?.data?.single_review?.value === 'true'
                    ? 48
                    : dynamicData?.data?.review_shadow?.value === 'true'
                      ? -16
                      : 16,
                  slidesPerView: dynamicData?.data?.single_review?.value === 'true'
                    ? 1
                    : dynamicData?.data?.controls_position?.value === 'aside'
                      ? 2
                      : 2.2,
                },
                1024: {
                  spaceBetween: dynamicData?.data?.single_review?.value === 'true'
                    ? 0
                    : dynamicData?.data?.review_shadow?.value === 'true'
                      ? -24
                      : 24,
                  slidesPerView: dynamicData?.data?.single_review?.value === 'true'
                    ? 1
                    : 3
                },
              }}
              loop={true}>

              <div className="swiper-wrapper">
                {
                  reviews?.map((review: review, idx: number) =>
                  {
                    return (
                      <SwiperSlide
                        key={idx}
                        className={`
                        ${dynamicData?.data?.review_shadow?.value === 'true' && 'pt-6 pb-16 px-4 md:px-8 lg:px-12'} 
                        ${dynamicData?.data?.review_shadow?.value === 'true' && dynamicData?.data?.single_review?.value === 'true' && dynamicData?.data?.content_align?.value === 'left' && ''} 
                        flex`}>

                        <Review review={review} />

                      </SwiperSlide>
                    )
                  })
                }

              </div>

            </Swiper>

            {/* Controls aside */}
            {
              dynamicData?.data?.controls_position?.value === 'aside' &&
              <div className="hidden md:flex absolute top-1/2 -mt-4 w-full mx-auto justify-between z-20">

                <button className={`${dynamicData?.data?.review_shadow?.value === 'true'
                  ? '-ml-12'
                  : '-ml-20'} prev-button rounded-full px-6 py-3 bg-primary-500 hover:bg-primary-600 text-white leading-none`}>

                  <span className="sr-only">Previous Slide</span>
                  <FAIcon type="solid" nameIco='fa-arrow-left-long' customClass="" />

                </button>

                <button className={`${dynamicData?.data?.review_shadow?.value === 'true'
                  ? '-mr-12'
                  : '-mr-20'} next-button rounded-full px-6 py-3 bg-primary-500 hover:bg-primary-600 text-white leading-none`}>

                  <span className="sr-only">Next Slide</span>
                  <FAIcon type="solid" nameIco='fa-arrow-right-long' customClass="" />

                </button>

              </div>
            }

          </div>

        </div>

        {/* Buttons */}
        <div className={`flex flex-wrap w-full space-y-4 md:space-y-0 md:space-x-4 md:w-auto`}>

          {/* Primary button */}
          {
            dynamicData?.data?.primary_button_text?.value &&
            <Link
              href={dynamicData?.data?.primary_button_link?.value ?? '#make-appointment'}
              className={`${getColor(
                ColorProperty.background,
                dynamicData?.data?.primary_button_color?.value,
                primary_button_color)} 
                relative text-[18px] leading-none inline-flex w-full md:w-auto justify-center text-center items-center rounded-xs px-8 py-4 font-medium duration-300 focus:outline-none focus:ring sm:w-auto cursor-pointer`}>

              {dynamicData?.data?.primary_button_text?.value}

            </Link>
          }

          {/* Secondary button */}
          {
            dynamicData?.data?.secondary_button_text?.value &&
            <Link
              href={dynamicData?.data?.secondary_button_link?.value ?? '#make-appointment'}
              className={`${getColor(
                ColorProperty.text,
                dynamicData?.data?.primary_button_color?.value,
                secondary_button_color)}
                relative text-[18px] leading-none inline-flex w-full md:w-auto justify-center text-center items-center rounded-xs px-8 py-4 font-medium duration-300 focus:outline-none focus:ring sm:w-auto cursor-pointer`}>

              {dynamicData?.data?.secondary_button_text?.value}

            </Link>
          }

        </div>

      </div>

    </section>
  )
}
