'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper/modules';
import 'swiper/css';
import { review, shop } from "@/types/graphql";
import Rating from "@/ui-components/rating";
import { getColor, ColorProperty } from "@/utils/color";
import { stdComponentDataList } from "@/utils/std-component-data";
import { ShopColors } from "@/utils/types";
import Link from "next/link";
import { useEffect, useState } from "react";
import FAIcon from "@/base-components/icons";

//-->dynamicData
let dynamicData = {
  uid: "legacy-reviews-lite",
  adapter: "reviews",
  data: {

    // Content
    "title": {
      sort: 1,
      name: 'Title',
      type: 'text',
      value: 'Our Reviews',
      section: 'content',
    },
    "reviews_number": {
      sort: 2,
      name: 'Reviews Number',
      type: 'number',
      value: 8,
      min_num: 3,
      max_num: 16,
      section: 'content'
    },
    "delay": {
      sort: 3,
      name: 'Delay',
      type: 'number',
      value: 12,
      min_num: 4,
      max_num: 32,
      section: 'content'
    },

    "primary_link_text": {
      sort: 4,
      name: 'Primary Link Text',
      type: 'text',
      value: 'Request Appointment',
      section: 'content',
    },
    "primary_link": {
      sort: 5,
      name: 'Primary Link',
      type: 'text',
      value: '#make-appointment',
      section: 'content',
    },
    "secondary_link_text": {
      sort: 6,
      name: 'Secondary Link Text',
      type: 'text',
      value: 'More From Our Customers',
      section: 'content',
    },
    "secondary_link": {
      sort: 7,
      name: 'Secondary Link',
      type: 'text',
      value: 'reviews',
      section: 'content',
    },

    // UI
    "section_bg_color": {
      sort: 1,
      name: 'Section Background Color',
      type: 'color',
      value: 'secondary',
      section: 'ui',
    },
    "section_bg_color_tint": stdComponentDataList('slider', 2, 'Section Background Color Tint', 'Section Background Color Tint', '', 50, 'ui', 'tints'),
    "section_text_color": {
      sort: 3,
      name: 'Section Background Color',
      type: 'color',
      value: 'neutral',
      section: 'ui',
    },
    "section_text_color_tint": stdComponentDataList('slider', 4, 'Section Background Color Tint', 'Section Background Color Tint', '', 600, 'ui', 'tints'),

    "review_style": {
      sort: 5,
      name: 'Review Style',
      type: 'radio',
      value: 'filled',
      options: [
        {
          'display': "Outlined",
          'value': 'outlined'
        },
        {
          'display': "Filled",
          'value': 'filled'
        }
      ],
      section: 'ui'
    },
    "review_bg_color": {
      sort: 6,
      name: 'Review Background Color',
      type: 'color',
      value: 'unset',
      section: 'ui',
    },
    "review_bg_color_tint": stdComponentDataList('slider', 7, 'Review Background Color Tint', 'Review Background Color Tint', '', 100, 'ui', 'tints'),
    "review_text_color": {
      sort: 8,
      name: 'Review Text Color',
      type: 'color',
      value: 'neutral',
      section: 'ui',
    },
    "review_text_color_tint": stdComponentDataList('slider', 9, 'Review Text Color Tint', 'Review Color Tint', '', 600, 'ui', 'tints'),
    "review_shadow": {
      sort: 10,
      name: 'Review Shadow',
      type: 'checkbox',
      value: 'false',
      section: 'ui',
    },

    "primary_link_color": {
      sort: 11,
      name: 'Primary Link Color',
      type: 'color',
      value: 'primary',
      section: 'ui',
    },

    "padding_top": stdComponentDataList('slider', 12, 'Top Padding', 'Top Padding', 'pt', 16),
    "padding_bottom": stdComponentDataList('slider', 13, 'Bottom Padding', 'Bottom Padding', 'pb', 16),
  },
};
//<--dynamicData

export default function Reviews({ data, config }: { data: shop, config: any })
{

  if (config && config.uid)
  {
    dynamicData = config;
  }

  const review_style: any = dynamicData?.data?.review_style?.value === 'filled'
    ?
    getColor(
      ColorProperty.background,
      dynamicData?.data?.review_bg_color?.value,
      dynamicData?.data?.review_bg_color_tint?.value) + ' dark:bg-neutral-800'
    :
    getColor(
      ColorProperty.border,
      dynamicData?.data?.review_bg_color?.value,
      dynamicData?.data?.review_bg_color_tint?.value) + ' border dark:border-neutral-800'

  const primary_button_color: ShopColors = {
    primary: 'bg-primary-500 hover:bg-primary-600 active:bg-primary-600 text-white',
    secondary: 'bg-secondary-500 hover:bg-secondary-600 active:bg-secondary-600 text-white',
    neutral: 'bg-neutral-500 hover:bg-neutral-600 active:bg-neutral-600 text-white',
    unset: 'bg-white hover:bg-primary-50 active:bg-primary-50 text-primary-500 hover:text-primary-600',
    custom: 'text-white',
  }

  const secondary_button_color: ShopColors = {
    primary: 'text-primary-500 hover:text-primary-600 active:text-primary-600 border border-neutral-200 hover:border-neutral-300 dark:border-neutral-600 dark:hover:border-neutral-500',
    secondary: 'text-secondary-500 hover:text-secondary-600 active:text-secondary-600 border border-neutral-200 hover:border-neutral-300 dark:border-neutral-600 dark:hover:border-neutral-500',
    neutral: 'text-neutral-500 hover:text-neutral-600 active:text-neutral-600 border border-neutral-200 hover:border-neutral-300 dark:border-neutral-600 dark:hover:border-neutral-500',
    unset: 'text-neutral-500 hover:text-neutral-600 active:text-neutral-600 border border-neutral-200 hover:border-neutral-300 dark:border-neutral-600 dark:hover:border-neutral-500',
    custom: 'border border-neutral-200 hover:border-neutral-300 dark:border-neutral-600 dark:hover:border-neutral-500',
  }

  // Array of selected reviews
  const selected_reviews: review[] = data?.reviews?.filter((review: review) =>
  {
    return review.selected;
  });

  // Array with random Google reviews with 5* rating. Used by default
  const [random_google_reviews, setRandomGoogleReviews] = useState<review[]>()
  useEffect(() =>
  {
    setRandomGoogleReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
    {
      return review.rating === 5 && review.source_id === 1;
    }).slice(0, dynamicData?.data?.reviews_number?.value));
  }, [data]);

  // Array with random AV reviews with 5* rating. Used in case shop has no Google reviews 
  const [random_av_reviews, setAVReviews] = useState<review[]>()
  useEffect(() =>
  {
    setAVReviews(data?.reviews?.sort(() => Math.random() - 0.5).filter((review: review) =>
    {
      return review.rating === 5 && review.source_id === 2;
    }).slice(0, dynamicData?.data?.reviews_number?.value));
  }, [data]);

  const reviews: review[] = selected_reviews && selected_reviews?.length > 0
    ?
    selected_reviews
    :
    random_google_reviews && random_google_reviews?.length > 0
      ?
      random_google_reviews
      :
      random_av_reviews && random_av_reviews?.length > 0
        ?
        random_av_reviews
        :
        []

  return (
    <section
      id={dynamicData?.uid}
      className={`
				${getColor(
        ColorProperty.background,
        dynamicData?.data?.section_bg_color?.value,
        dynamicData?.data?.section_bg_color_tint?.value)}`}>

      <div className={`
				${dynamicData?.data?.padding_top?.value ?? 'pt-12'} 
				${dynamicData?.data?.padding_bottom?.value ?? 'pb-12'} 
				relative mx-auto max-w-screen-xl`}>

        {/* Title */}
        {
          dynamicData?.data?.title?.value &&
          <h2 className={`${getColor(
            ColorProperty.text,
            dynamicData?.data?.review_text_color?.value,
            Number(dynamicData?.data?.review_text_color_tint?.value) + 300)}
						font-bold capitalize tracking-tight mb-10 px-4 md:px-6 lg:px-8 xl:px-0`}>

            {dynamicData?.data?.title?.value}

          </h2>
        }

        <Swiper
          autoplay={{
            disableOnInteraction: false,
            delay: dynamicData?.data?.delay?.value > 0
              ? (dynamicData?.data?.delay?.value ?? 12) * 1000
              : 12000,
            pauseOnMouseEnter: true,
          }}
          navigation={{
            nextEl: '.next-button',
            prevEl: '.prev-button',
          }}
          modules={[Autoplay, Navigation]}
          spaceBetween={16}
          slidesPerView={1.2}
          breakpoints={{
            640: {
              slidesPerView: 2.2,
              spaceBetween: 20
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 24
            },
          }}
          loop={true}>

          <div className="swiper-wrapper">
            {
              reviews?.map((review: review, idx: number) => 
              {
                return (
                  <SwiperSlide key={idx}>

                    <blockquote
                      key={idx}
                      className={`
											${review_style}
											${getColor(
                        ColorProperty.text,
                        dynamicData?.data?.review_text_color?.value,
                        dynamicData?.data?.review_text_color_tint?.value)}
												flex flex-col justify-between h-80 px-6 py-8 rounded`}>

                      <div className="mb-2 md:mb-6">

                        <div className="flex justify-between">

                          <Rating rating={review?.rating ?? 5} color={''} />

                          {/* Date */}
                          {
                            review?.date &&
                            <span className="text-p5 leading-none">
                              {new Date(review?.date)?.toLocaleDateString('en-US')}
                            </span>
                          }

                        </div>

                        {/* Content */}
                        <div className="mt-4">
                          <p className="mb-2 line-clamp-5">

                            {review?.content ?? '—'}

                          </p>
                        </div>

                      </div>

                      <footer className={`${getColor(
                        ColorProperty.text,
                        dynamicData?.data?.review_text_color?.value,
                        Number(dynamicData?.data?.review_text_color_tint?.value) + 300)}
												capitalize text-right font-bold`}>

                        {/* Author */}
                        {review?.author_name?.toLowerCase() ?? "Unknown Author"}

                        {/* Location */}
                        {
                          review?.location &&
                          <span className="font-normal">

                            {' from ' + review?.location}

                          </span>
                        }

                      </footer>

                    </blockquote>

                    {/* Shadow */}
                    {
                      dynamicData?.data?.review_shadow?.value === 'true' &&
                      <div className="absolute flex inset-0 rounded shadow hover:shadow-md duration-300 mix-blend-overlay" />
                    }

                  </SwiperSlide>
                )
              })
            }
          </div>

        </Swiper>

        <style jsx global>
          {`
            .swiper {
              overflow-x: clip;
              overflow-y: visible;

              padding-left: 1rem;
            }
        
            @media (min-width: 768px) {
              .swiper {
                padding-left: 1.5rem;
              }
            }
                      
            @media (min-width: 1024px) {
              .swiper {
                padding-right: 1.5rem;
                margin-left: -1.5rem;
                margin-right: -1.5rem;
              }
            }

            .swiper-slide,
            .swiper-slide-prev {
							opacity: 0;
        		}

						.swiper-slide-active,
            .swiper-slide-next {
							opacity: 1;
						}
						
						@media (min-width: 768px) {
							.swiper-slide-active,
							.swiper-slide-next,
							.swiper-slide-next + .swiper-slide {
								opacity: 1;
							}
						}
					`}
        </style>

        {/* Swiper desktop controls */}
        <div className="hidden xl:flex absolute top-1/2 -mt-4 w-full mx-auto justify-between z-20">

          <button className="prev-button flex border border-primary-500 hover:border-primary-600 rounded-full -ml-20 p-4 hover:text-primary-600 duration-300">
            <span className="sr-only">Previous Slide</span>
            <FAIcon type="solid" nameIco='fa-chevron-left' customClass="leading-none h-4 w-4" />
          </button>

          <button className="next-button flex border border-primary-500 hover:border-primary-600 rounded-full -mr-20 p-4 hover:text-primary-600 duration-300">
            <span className="sr-only">Next Slide</span>
            <FAIcon type="solid" nameIco='fa-chevron-right' customClass="leading-none h-4 w-4" />
          </button>

        </div>

        {/* Links */}
        {
          (dynamicData?.data?.primary_link_text?.value || dynamicData?.data?.secondary_link_text?.value) &&
          <div className="flex flex-wrap w-full space-y-4 md:space-y-0 md:space-x-4 md:w-auto text-center mt-12 px-4 md:px-6 lg:px-8 xl:px-0">

            {/* Primary link */}
            {
              dynamicData?.data?.primary_link_text?.value &&
              <Link
                id={dynamicData.uid + '-primary-button'}
                href={dynamicData?.data?.primary_link?.value ?? '#make-appointment'}
                className={`${getColor(
                  ColorProperty.background,
                  dynamicData?.data?.primary_link_color?.value,
                  primary_button_color)} 
								inline-flex w-full items-center justify-center rounded-sm px-6 py-3 font-medium text-center duration-300 focus:outline-none focus:ring sm:w-auto cursor-pointer`}>

                {dynamicData?.data?.primary_link_text?.value}

              </Link>
            }

            {/* Secondary link */}
            {
              dynamicData?.data?.secondary_link_text?.value &&
              <Link
                id={dynamicData.uid + '-secondary-button'}
                href={dynamicData?.data?.secondary_link?.value ?? '#make-appointment'}
                className={`${getColor(
                  ColorProperty.text,
                  dynamicData?.data?.primary_link_color?.value,
                  secondary_button_color)} 
								inline-flex w-full items-center justify-center rounded-sm px-6 py-3 font-medium text-center duration-300 focus:outline-none focus:ring sm:w-auto cursor-pointer`}>

                {dynamicData?.data?.secondary_link_text?.value}

              </Link>
            }

          </div>
        }

      </div>

    </section>

  )
}
