'use client';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { DsxImage, DsxImageClient } from "@/base-components/image";
import FAIcon from "@/base-components/icons";
import 'swiper/css';
import 'swiper/css/pagination';
import React from 'react';
import { getColor, ColorProperty } from "@/utils/color";

export default function TeamBanner({ dynamicData, members }: { dynamicData: any, members: any })
{

	const skew = dynamicData?.data?.skew?.value === 'true' && "-skew-x-[14deg] transform-gpu";
	const unskew = dynamicData?.data?.skew?.value === 'true' && "skew-x-[14deg] transform-gpu";

	function TeamMember({ member }: { member: any })
	{
		return (
			<div className={`
				${getColor(
				ColorProperty.background,
				dynamicData?.data?.member_bg_color?.value,
				dynamicData?.data?.member_bg_color_tint?.value)}
				${skew} 
				flex flex-col justify-between rounded`}>

				{/* Shadow */}
				{
					dynamicData?.data?.member_shadow?.value === 'true' &&
					<div className="absolute flex inset-0 rounded shadow hover:shadow-md duration-300 mix-blend-overlay" />
				}

				{/* Feature image */}
				<div className={`flex relative min-h-52 w-full rounded overflow-hidden`}>
					<DsxImageClient dsxImage={member?.image} />
				</div>

				<div className="px-6 py-8 space-y-2 min-h-52">

					{/* Name */}
					<h3 className={`${unskew}
						text-h5 font-bold capitalize italic line-clamp-2`}>
						{member?.first_name ?? '—'}
					</h3>

					{/* Review text */}
					<div
						className={`w-full line-clamp-4 list-disc`}
						dangerouslySetInnerHTML={{ __html: member?.responsibilities ?? '—' }}
					/>

				</div>
			</div>
		)
	}

	return (
		<>
			{/* Slider */}
			<div className={`relative`}>

				<Swiper
					autoplay={{
						disableOnInteraction: false,
						delay: dynamicData?.data?.delay?.value > 0
							? (dynamicData?.data?.delay?.value ?? 12) * 1000
							: 12000,
						pauseOnMouseEnter: true,
					}}
					navigation={{
						nextEl: '.next-button',
						prevEl: '.prev-button',
					}}
					modules={[Autoplay, Navigation]}
					spaceBetween={
						dynamicData?.data?.member_shadow?.value === 'true'
							? 16
							: 12
					}
					slidesPerView={1}
					breakpoints={{
						640: {
							slidesPerView: 2,
							spaceBetween: dynamicData?.data?.member_shadow?.value === 'true'
								? 24
								: 20
						},
						1024: {
							slidesPerView: 3,
							spaceBetween: dynamicData?.data?.member_shadow?.value === 'true'
								? 32
								: 24
						},
					}}
					loop={true}>

					<div className="swiper-wrapper">
						{
							members?.map((member: any, idx: number) =>
							{
								return (
									<SwiperSlide
										key={idx}
										className={`pt-12`}>

										<TeamMember member={member} />

									</SwiperSlide>
								)
							})
						}
					</div>

				</Swiper>

				<style jsx global>
					{`
						.swiper {
							overflow: visible;
						}
						
						.swiper-slide {
							opacity: 0.5;
							transition: opacity 0.3s ease;
        		}

						.swiper-slide-active {
							opacity: 1;
						}
						
						@media (min-width: 768px) {
							.swiper-slide-active,
							.swiper-slide-next {
								opacity: 1;
							}
						}

						@media (min-width: 1024px) {
							.swiper-slide-active,
							.swiper-slide-next,
							.swiper-slide-next + .swiper-slide {
								opacity: 1;
							}
						}

					`}
				</style>

				{/* Controls aside */}
				<div className="hidden md:flex absolute top-1/2 -mt-4 w-full mx-auto justify-between z-20">

					<button className={`${skew}
					prev-button -ml-8 rounded-xs px-6 py-3 bg-primary-500 hover:bg-primary-600 text-white leading-none`}>

						<span className="sr-only">Previous Slide</span>
						<FAIcon type="solid" nameIco='fa-arrow-left-long' customClass="" />

					</button>

					<button className={`${skew}
					next-button -mr-8 rounded-xs px-6 py-3 bg-primary-500 hover:bg-primary-600 text-white leading-none`}>

						<span className="sr-only">Next Slide</span>
						<FAIcon type="solid" nameIco='fa-arrow-right-long' customClass="" />

					</button>

				</div>

			</div>
		</>
	)
}
